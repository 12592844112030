<template>
  <static-fullscreen-card>
    <template v-slot:title>
      <div class="d-flex">
        <v-autocomplete
          v-if="getAccess('cabinet.workStatistics.viewAll')"
          :items="$root.dirs['user']"
          item-text="text"
          item-value="value"
          v-model="user_id"
          label=""
          hide-details
          dense
          class="mt-1"
        />

        <span> Ваша статистика</span>
      </div>
    </template>
    <template v-slot:header></template>
    <template v-slot:actions>
      <a-btn-refresh @click="$refs.table.updateData()" />
    </template>
    <a-table-f-data2 ref="table" :dataTable="data" @after-fetch="makeFooter($event)" :model="model" :footer="footer" :useQuery="false" @click="onClickRow($event)">
    </a-table-f-data2>
  </static-fullscreen-card>
</template>

<script>
import { getAccess, keyDetect, doubleClickDetect, genModel } from "@/components/mixings";

export default {
  components: {},
  mixins: [getAccess, keyDetect, doubleClickDetect, genModel],
  data() {
    return {
      footer: null,
      user_id: this.$root.profile.id || 0,
      m: {
        list: [
          { name: "object_id", title: "Объект", type: "select", dir: "object" },
          { name: "name", title: "Наименование", type: "string", sortable: true, width: 250, style: "white-space: normal;" },
          { name: "createdon", title: "Дата", type: "date", sortable: true, width: 150 },
          { name: "work_cost", title: "Стоимость", type: "number", sortable: true, width: 100, footer: "sum" },
          { name: "value_fut", title: "Будущие", type: "number", sortable: true, width: 100, footer: "sum" },
          { name: "value_in", title: "К получению", type: "number", sortable: true, width: 100, footer: "sum" },
          { name: "value_out", title: "Получено", type: "number", sortable: true, width: 100, footer: "sum" },
          { name: "status", title: "Статус", type: "select", options: "status_work", sortable: true, width: 150 },
        ],
      },
      data: [],
    };
  },
  computed: {
    model() {
      let res = this.getModelList(this.m, "list", false);
      return res;
    },
  },
  created() {
    if (!this.idShow) this.$root.title = this.$route.meta.title || "Статистика";
    this.fitchData();
  },
  watch: {
    user_id() {
      this.fitchData();
    },
    value() {
      if (this.value) {
      }
    },
  },
  methods: {
    onClickRow() {},
    async fitchData() {
      let data;
      let params = {};
      const d = await this.$axios.post("/report_sql", { name: "getWorkStatistics", params: { user_id: this.user_id } });
      if (d.data && d.data.data) {
        data = d.data.data;
      }
      this.makeFooter(data);
      this.data = data;

      this.loading = false;
    },
    async getData() {
      let resp = await this.$axios.post("/report_sql", { name: "getWorkStatistics", params: { user_id: 28 } });
    },
    makeFooter(data) {
      let footer = {};
      footer["Показано"] = data.length;
      this.model.forEach((m) => {
        if (m.footer) {
          if (m.footer == "sum") {
            let res = data.reduce((a, i) => a + (i?.[m.name] || 0), 0) || 0;
            footer[m.title] = res.toLocaleString();
          }
        }
      });
      this.footer = footer;
    },
  },
};
</script>
